import React from 'react';
import logo from './logo.svg';
import './App.css';

const apiKey = '0e3927bc-970d-4eac-b1f2-13e5d8f5a4c3';

// let transak = new transakSDK({
//   apiKey: apiKey, // (Required)
//   environment: 'STAGING', // (Required)
// });

function App() {

  return (
    <div className="App">
      <div style={{ position: "relative", width: "500px", height: "80dvh", margin: "auto", "box-shadow": "0 0 15px #1461db", "border-radius": "15px", "overflow": "hidden" }}>
        <iframe
          src={`https://global-stg.transak.com?apiKey=${apiKey}&environment=staging`}
          allow="camera;microphone;fullscreen;payment"
          style={{ height: "100%", width: "100%", border: "none" }}>
        </iframe>
      </div>
    </div>
  );
}

export default App;
